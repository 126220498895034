






































































































































































































































import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator'
import InputSearch from '@/components/common/InputSearch.vue'
import SecurityText from '@/components/common/SecurityText/index.vue'
import PhotoDialog from '@/components/common/photoDialog.vue'
import Modal from '@/components/common/Modal'
import TagsDialog from './TagsDialog.vue'
import ResidentDrawer from './ResidentDrawer.vue'
import PupilAdder from './PupilAdder.vue'
import PupilTable from './PupilTable.vue'
import { resident, options } from '@/utils/options'
import { is, phoneNumber, validate } from '@/utils/helpers'
import { exportStatistic } from '@/utils/export'
import mitt from '@/utils/mitt'
import _ from 'lodash'
import { ElTable } from 'element-ui/types/table'
import ChangeRoomDialog from '@/views/PopulationManagement/ResidentManagement/components/ChangeRoomDialog.vue'

const drawer = Modal(ResidentDrawer)

@Component({
  components: {
    InputSearch,
    SecurityText,
    PhotoDialog,
    TagsDialog,
    PupilAdder,
    PupilTable,
    ChangeRoomDialog
  }
})
export default class ResidentList extends Vue {
  @Prop() area: AnyObj

  @Ref('table') table?: ElTable
  @Ref('photoDialog') photoDialog?: PhotoDialog
  @Ref('tagsDialog') tagsDialog?: Vue
  @Ref('pupilAdder') pupilAdder?: PupilAdder

  loading = false
  residentList: AnyObj[] = []
  counts: AnyObj = []
  residents: AnyObj[] = []
  total = 0
  filterData: AnyObj = { searchType: 'name' }
  searchRules: Record<string, (v: string) => string | void> = {
    tel: v => {
      if (!validate.phoneNumber(v) && !validate.landlineNumber(v)) return '请输入正确的电话号码'
    },
    identityNumber: v => {
      if (!validate.identityNumber(v)) return '请输入正确的证件号'
    },
    passportNumber: v => {
      if (!validate.passportNumber(v)) return '请输入正确的证件号'
    },
    hkmoPermitNumber: v => {
      if (!validate.hkmoPermitNumber(v)) return '请输入正确的证件号'
    },
    twnPermitNumber: v => {
      if (!validate.twnPermitNumber(v)) return '请输入正确的证件号'
    }
  }
  pagination = { pageSize: 20, currentPage: 1 }
  exportLoading = false
  placeholder = '请输入住户姓名查询'
  showChangeRoomDialog = false
  resident: AnyObj = {}

  get typeOptions() {
    return resident.types
  }

  get searchTypeOptions() {
    return resident.searchTypes.concat(resident.identityTypes)
  }

  get statistic() {
    const statistic: Obj<string, number> = {
      renter: 0,
      pupil: 0,
      landlord: 0,
      relative: 0,
      permanent: 0,
      migrant: 0
    }

    this.counts.forEach(({ count, type }: AnyObj) => {
      statistic[type] = parseInt(count)
    })

    const { renter, landlord, relative } = statistic
    statistic.total = renter + landlord + relative

    return statistic
  }

  get config() {
    return this.$store.state.config
  }

  get params() {
    const { dateRange, searchType, searchText } = this.filterData
    const [startTime, endTime] = dateRange ?? []
    const { currentPage, pageSize } = this.pagination

    const fuzzyFields = ['name']

    const params: AnyObj = {
      areaId: this.area.id,
      offset: (currentPage - 1) * pageSize,
      limit: pageSize,
      startTime,
      endTime,
      [searchType]: searchText,
      fuzzy: fuzzyFields.includes(searchType),
      ..._.omit(this.filterData, ['dateRange', 'searchType', 'searchText'])
    }

    if (searchType === 'tel' && validate.phoneNumber(searchText)) {
      params[searchType] = phoneNumber.standardize(searchText)
    }

    return _.pickBy(params, is.ava)
  }

  get activePeriod() {
    const { areas } = this.$store.state.areas

    return areas.length > 0 && areas[0].activePeriod ? areas[0].activePeriod : 0
  }

  getIdentity(row: AnyObj) {
    for (const { value } of resident.identityTypes) {
      if (row[value]) {
        return { type: value, value: row[value] }
      }
    }

    return { type: 'identityNumber', value: '' }
  }

  getType(value: string) {
    return options(resident.types).find(value) ?? {}
  }

  isRenter(person: AnyObj) {
    // console.log(person)
    return person.type === 'renter'
  }

  getTags(row: AnyObj) {
    return row.tags && row.tags.length > 0 ? row.tags.slice(0, 1) : []
  }

  getRemainTags(row: AnyObj) {
    return row.tags && row.tags.length > 1 ? row.tags.slice(1) : []
  }

  @Watch('area')
  watchArea() {
    this.fetch()
  }

  @Watch('residents')
  watchResident() {
    this.residentList = this.residents.map((r: AnyObj) => ({ ...r, pupils: [] }))
  }

  @Watch('pagination', { deep: true })
  async watchPagination() {
    await this.fetchResidentList()

    if (this.table) {
      this.table.bodyWrapper.scrollTop = 0
    }
  }

  mounted() {
    mitt.on(['residents:change', 'houses/residents:change'], () => this.fetch())
    drawer.on('updated', () => this.fetchResidentList())
  }

  fetch() {
    this.fetchResidentCounts()
    this.fetchResidentList()
  }

  setParams(data?: AnyObj) {
    this.filterData = { ...this.filterData, ...data }
  }

  async fetchResidentCounts() {
    const { data } = await this.$api.europa.getPersonCount({ areaId: this.params.areaId })

    if (data.code === 0) {
      this.counts = data.data
    }
  }

  selectType(val: string) {
    switch (val) {
      case 'name':
        this.placeholder = '请输入住户姓名查询'
        break
      case 'tel':
        this.placeholder = '请输入联系电话查询'
        break
      case 'identityNumber':
        this.placeholder = '请输入身份证号查询'
        break
      case 'passportNumber':
        this.placeholder = '请输入护照查询'
        break
      case 'hkmoPermitNumber':
        this.placeholder = '请输入港澳居民来往内地通行证查询'
        break
      case 'twnPermitNumber':
        this.placeholder = '请输入台湾居民来往大陆通行证查询'
        break
    }
  }

  async fetchResidentList() {
    this.loading = true
    const params = _.pickBy(this.params, is.ava)
    const { data } = await this.$api.europa.getPersons(params)

    this.loading = false

    if (data.code === 0) {
      this.residents = data.data
      this.total = data.count
    }
  }

  async exportResult() {
    this.exportLoading = true

    const params = _.pickBy(this.params, is.ava)
    const { data } = await this.$api.europa.exportPerson(params)
    this.exportLoading = false

    exportStatistic(data, 'csv', 'resident.csv')
  }

  filter() {
    this.pagination.currentPage = 1
    this.fetchResidentList()
  }

  search() {
    const { searchType, searchText } = this.filterData

    if (searchText) {
      const validate = this.searchRules[searchType]
      const message = validate && validate(searchText)

      if (message) {
        return this.$message({ message, type: 'error' })
      }
    }

    this.filter()
  }

  createResident() {
    drawer.open({ mode: 'create', data: { areaId: this.area.id } })
  }

  updateResident(resident: AnyObj) {
    const { type, roomId } = resident

    drawer.open({ mode: 'update', data: { ...resident, type, roomId } })
  }

  viewResident(resident: AnyObj) {
    this.$router.push({ name: '住户信息查看', params: { id: resident.personId } })
  }

  deleteResident(resident: AnyObj) {
    const { personId, leaseId } = resident

    this.$confirm('住户一经删除无法恢复，是否确定删除?', '提示', {
      type: 'warning',
      callback: async action => {
        if (action === 'confirm') {
          const { data } = await this.$api.europa.deletePerson(personId, leaseId)

          if (!data.error) {
            this.$message({ message: '删除成功', type: 'success' })
            mitt.emit('residents:change')
          }
        }
      }
    })
  }
  changeRoom(resident: AnyObj) {
    this.resident = resident
    this.showChangeRoomDialog = true
  }

  addImage(resident: AnyObj) {
    this.photoDialog?.open({ ...resident, id: resident.personId })
  }

  updateTags(resident: AnyObj) {
    this.tagsDialog?.open('editTags', { ...resident, id: resident.personId })
  }

  commandResident(command: string, resident: AnyObj) {
    switch (command) {
      case 'addImage':
        return this.addImage(resident)
      case 'updateTags':
        return this.updateTags(resident)
      case 'viewPupils':
        return this.table?.toggleRowExpansion(resident)
      case 'createPupil':
        return this.pupilAdder?.open(resident, () => this.fetchPupils(resident))
      case 'delete':
        return this.deleteResident(resident)
      case 'changeRoom':
        return this.changeRoom(resident)
    }
  }

  async fetchPupils(parent: AnyObj) {
    const { data } = await this.$api.europa.getPupils(parent.personId)

    if (data.code === 0) {
      parent.pupils = data.data
    }
  }
}
