

























import { Vue, Component, Watch, Ref } from 'vue-property-decorator'
import { compressImg, imageRotate } from '@/components/common/compressImg'
import { upload } from '@/utils/cos'
import base64ToFile from '@/utils/base64ToFile'

@Component({
  name: 'upload',
  components: {}
})
export default class Upload extends Vue {
  @Ref('hiddenInput') readonly hiddenInput: HTMLDivElement
  private inputimageUrl = ''
  private previewImgUrl = ''
  private tipShow = false
  private tipText = ''
  errorMap: any = {
    'image is not qualified': '图片质量不合格',
    'no face detected': '未检测到人脸',
    'More than one face detected': '检测到多个人脸'
  }

  @Watch('previewImgUrl')
  watchImgUrl() {
    this.$emit('change', this.previewImgUrl)
  }
  private message(msg: string, type: MessageType) {
    this.$message({
      message: msg,
      type: type
    })
  }
  public init(url: '') {
    this.inputimageUrl = ''
    this.previewImgUrl = url
    this.tipShow = false
    this.tipText = ''
  }
  private focusInput() {
    this.tipShow = false
    this.tipText = ''
  }
  // 通过点击加载按钮触发
  private async loadPic(url: string) {
    const regUrl = /^(ht|f)tps?:\/\/.*/

    if (!url) {
      this.tipShow = true
      this.tipText = '网址为空，请重新输入'
    } else if (!regUrl.test(url)) {
      this.previewImgUrl = url
      this.tipShow = true
      this.tipText = '网址无效，请重新输入'
      this.previewImgUrl = ''
    } else {
      this.checkImg(url)
    }
  }
  // 打开图片上传
  private uploadHeadImg() {
    this.hiddenInput.click()
  }

  // 图片旋转后的回调函数
  private async getImage(data: any) {
    const imgFile = base64ToFile(data)

    this.savePic(imgFile)
  }

  private rotateImage(file: any) {
    imageRotate(file, this.getImage)
  }
  // 将头像显示
  private async handleFile(e: any) {
    const $target = e.target || e.srcElement
    const file = $target.files[0]
    const result = await compressImg(file)
    let imgUrl: any = ''
    result.onload = data => {
      const res: any = data.target || data.srcElement

      imgUrl = res.result

      this.rotateImage(imgUrl)
    }

    if (!imgUrl && !result.onload) {
      //兼容ie10
      imgUrl = result.result

      this.previewImgUrl = imgUrl
    }
    e.target.value = ''
  }

  // 将图片存到对象存储
  private async savePic(file: any) {
    this.previewImgUrl = ''

    const res: any = await upload(file, () => this.$api.europa.secretConsole())

    if (res.statusCode === 200) {
      const imgData = {
        imageUrl: 'http://' + res.Location
      }

      this.checkImg(imgData.imageUrl)
    }
  }
  private async checkImg(url = '') {
    const { data } = await this.$api.europa.imageQuality(url)

    if (data.code === 0) {
      const res = data.data

      if (res.qualified) {
        this.previewImgUrl = url
      } else {
        const reason =
          res.reason && this.errorMap[res.reason] ? this.errorMap[res.reason] : '图片质量不合格'
        this.message(reason, 'error')
      }
    }
  }

  private loadError() {
    if (this.previewImgUrl) {
      this.tipShow = true
      this.tipText = '无法读取到图片文件, 请重新输入'
    }
  }
}
