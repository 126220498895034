


















import { Vue, Component } from 'vue-property-decorator'
import PageTitle from '@/components/common/pageTitle.vue'
import Areas from '@/components/common/Areas/index.vue'
import ResidentList from './components/ResidentList.vue'

@Component({
  components: {
    PageTitle,
    Areas,
    ResidentList
  }
})
export default class ResidentsManagement extends Vue {
  currentArea: AnyObj = {}

  get isChildRoute() {
    return this.$route.name !== '住户管理'
  }

  areaChange(area: AnyObj) {
    this.currentArea = area
  }
}
