





















































import { Vue, Component, Ref } from 'vue-property-decorator'
import CopyText from '@/components/common/CopyText.vue'
import UploadImg from '@/components/common/uploadImg.vue'
import { ElForm } from 'element-ui/types/form'
import { identityNumber, is, validate } from '@/utils/helpers'
import _ from 'lodash'

@Component({ name: 'PupilAdder', components: { CopyText, UploadImg } })
export default class PupilAdder extends Vue {
  @Ref('formNode') readonly formNode: ElForm
  @Ref('uploadNode') readonly uploadNode: UploadImg
  private showFlag = false
  private loading = false
  private submitLoading = false
  private imageUrl = ''
  private previewImgUrl = ''
  private nameIdData: any = {
    name: '',
    identityNumber: '',
    cardNumber: '',
    gender: ''
  }
  private name = ''
  private id = ''
  private curl = ''
  private identityNumber = ''
  private personId = ''
  private leaseId = ''
  private noData = require('@/assets/images/dashboard/noData.png')
  private validityDate: any = ''
  private type = 0
  private rules = {
    name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
    identityNumber: [
      {
        validator: (rule: any, value: string, callback: Function) => {
          if (value && !validate.identityNumber(value)) callback(new Error('请输入正确的身份证号'))
          else callback()
        },
        trigger: 'blur'
      }
    ]
  }
  deactivated() {
    this.showFlag = false
  }
  get user() {
    return this.$store.state.user || {}
  }

  private changeDate(val: any) {
    this.validityDate = val
  }
  private changeImg(val: any) {
    this.previewImgUrl = val
  }
  private async addMsg() {
    const pupilsData = { ...this.nameIdData }

    if (this.previewImgUrl) {
      pupilsData.imageUrl = this.previewImgUrl
    }

    this.formNode.validate(async (valid: any) => {
      if (valid) {
        if (
          pupilsData.cardNumber !== '' ||
          pupilsData.identityNumber !== '' ||
          pupilsData.imageUrl
        ) {
          try {
            this.submitLoading = true
            if (pupilsData.identityNumber === '') {
              delete pupilsData.identityNumber
              delete pupilsData.gender
            } else {
              pupilsData.gender = identityNumber.getGender(pupilsData.identityNumber)
            }
            const { data } = await this.$api.europa.addPupils(
              this.personId,
              _.pickBy(pupilsData, is.ava)
            )
            if (data && data.code === 0) {
              this.message('添加成功', 'success')
              this.showFlag = false
              this.$emit('refresh')

              if (this.callback) {
                this.callback()
                this.callback = null
              }
            }
          } finally {
            this.submitLoading = false
          }
        } else {
          this.message(
            '添加被监护人时门禁卡号、身份证号和通行图片' +
              '根据实际情况按需添写，但至少填写其中一个。',
            'error'
          )
        }
      }
    })
  }

  async open(data: AnyObj = {}, callback?: Function) {
    this.showFlag = true

    this.personId = data.personId

    this.$nextTick(() => {
      this.formNode.resetFields()
      this.uploadNode.init('')
    })

    this.nameIdData.name = ''
    this.nameIdData.identityNumber = ''
    this.nameIdData.cardNumber = ''
    this.previewImgUrl = ''

    if (callback) this.callback = callback
  }
  private message(msg: string, type: MessageType) {
    this.$message({
      message: msg,
      type: type
    })
  }
}
