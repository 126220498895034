import { v4 } from 'uuid'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const COS = require('cos-js-sdk-v5')

let cos: any

async function getConfig(secret: Function) {
  if (cos && cos.options.ExpiredTime > Date.now() / 1000 + 10) {
    return cos
  }

  const { data } = await secret()

  if (data.code === 0) {
    const res = data.data

    cos = new COS({
      SecretId: res.credentials.tmpSecretId,
      SecretKey: res.credentials.tmpSecretKey,
      XCosSecurityToken: res.credentials.sessionToken,
      StartTime: res.startTime, // 时间戳，单位秒，如：1580000000
      ExpiredTime: res.expiredTime
    })

    cos.region = res.region
    cos.bucket = res.bucket

    return cos
  }
}

export const upload = async function (file: any, secret: Function) {
  cos = await getConfig(secret)

  const promise = new Promise<any>((resolve, reject): any => {
    cos.putObject(
      {
        Bucket: cos.bucket,
        Region: cos.region,
        Key: `/tmp/web/${v4()}`,
        StorageClass: 'STANDARD',
        Body: file
      },
      function (err: any, data: any) {
        if (err) {
          reject(err)
        }
        resolve(data)
      }
    )
  })

  return promise
}
