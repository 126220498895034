













































import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import SecurityText from '@/components/common/SecurityText/index.vue'
import PhotoDialog from '@/components/common/photoDialog.vue'

@Component({ components: { SecurityText, PhotoDialog } })
export default class ResidentTable extends Vue {
  @Prop() private readonly pupils: AnyObj[]
  @Prop() private readonly fetchPupils: Function

  @Ref('photoDialog') photoDialog?: PhotoDialog

  loading = false

  created() {
    this.fetch()
  }

  async fetch() {
    this.loading = true
    await this.fetchPupils()
    this.loading = false
  }

  addImage(pupil: AnyObj) {
    this.photoDialog?.open({ ...pupil, id: pupil.personId })
  }

  deletePupil(pupil: AnyObj) {
    const { personId, leaseId } = pupil

    this.$confirm('被监护人一经删除无法恢复，是否确定删除?', '提示', {
      type: 'warning',
      callback: action => {
        if (action === 'confirm') {
          this.$api.europa.deletePerson(personId, leaseId).then(() => {
            this.$message({ message: '删除成功', type: 'success' })
            this.fetch()
          })
        }
      }
    })
  }
}
