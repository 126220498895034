
































































import { Vue, Component, PropSync, Prop, Ref } from 'vue-property-decorator'
import { DatePickerOptions } from 'element-ui/types/date-picker'
import _ from 'lodash'
import { fetchAll } from '@/utils'
import { is } from '@/utils/helpers'
import { ElForm } from 'element-ui/types/form'
import mitt from '@/utils/mitt'

interface UpdateRoomsParams {
  name: string
  // 变更前的roomId
  originRoomId: string
  expireAt: string
  // 变更后的roomId
  roomId: string
}

@Component
export default class ChangeRoomDialog extends Vue {
  @PropSync('visible') syncVisible: boolean
  @Prop(Object) resident: AnyObj

  @Ref() form: ElForm

  datePickerOptions: DatePickerOptions = {
    disabledDate: time => time.getTime() < Date.now(),
    shortcuts: [
      { text: '3个月', onClick: picker => picker.$emit('pick', this.$moment().add(3, 'month')) },
      { text: '6个月', onClick: picker => picker.$emit('pick', this.$moment().add(6, 'month')) },
      { text: '12个月', onClick: picker => picker.$emit('pick', this.$moment().add(12, 'month')) }
    ]
  }
  houseProps = { label: 'name', value: 'id' }
  roomList: AnyObj[] = []
  houseList: AnyObj[] = []
  roomsLoading = false
  housesLoading = false
  // 确认按钮loading
  submitButtonLoading = false
  originRoomId = ''
  formData: AnyObj = {}

  get rules() {
    return {
      roomId: [{ required: true, message: '请选择居住房屋房间', trigger: 'blur' }],
      expireAt: [{ required: true, message: '请选择有效期', trigger: 'blur' }]
    }
  }

  // 展示村一级地址
  get address() {
    return this.formData.address ? this.formData.address.split('/')[0] : '-'
  }

  setFormData(formData: AnyObj) {
    this.formData = { ...this.formData, ...formData }
  }
  async init() {
    this.roomList = []
    this.houseList = []
    this.formData = this.resident
    // 保存之前的 roomId
    this.originRoomId = this.formData.roomId
    this.formData.roomId = ''
    this.housesLoading = true
    this.houseList = await this.fetchHouseList()
    this.housesLoading = false
  }
  dialogOpen() {
    this.init()
  }
  async updateRooms(id: string, data: UpdateRoomsParams) {
    const { data: res }: any = await this.$api.europa.updateRooms(id, data)
    if (res?.code === 0) {
      this.$message({ message: '更换成功', type: 'success' })
      mitt.emit('residents:change')
    } else {
      this.$message({ message: '操作失败', type: 'error' })
    }
  }
  async submit() {
    this.form.validate(async (valid: any) => {
      if (valid) {
        this.submitButtonLoading = true
        const { roomId, expireAt, name } = this.formData
        // 时间格式转换为2022-03-31T15:59:59.999Z
        const expireAtFormat = this.$moment(expireAt).format()
        await this.updateRooms(this.formData.personId, {
          roomId,
          expireAt: expireAtFormat,
          name,
          originRoomId: this.originRoomId
        })
        this.submitButtonLoading = false
        this.syncVisible = false
      }
    })
  }
  // 请求当前用户房屋所在地村一级下的房屋列表
  async fetchHouseList({ offset = 0, ids = [] as string[], name = '' } = {}) {
    const { countryId } = this.formData
    if (countryId) {
      const limit = 100
      const { data } = await this.$api.europa.getHouseLists(
        // name为输入框搜索参数
        _.pickBy({ areaIds: [countryId], limit, offset, ids, fuzzy: true, name }, is.ava)
      )
      if (data.code === 0) {
        return data.data
      }
    }
    return []
  }
  async searchHouses(filterText: string) {
    const houseList = await this.fetchHouseList({ name: filterText })
    return houseList
  }
  async loadHouses(options: AnyObj[], filterText: string) {
    const houseList = await this.fetchHouseList({ name: filterText, offset: options.length })
    return houseList
  }

  async handleHouseChange(houseId: string) {
    this.formData.roomId = ''
    if (houseId) {
      this.roomsLoading = true
      await this.fetchRoomList(houseId)
      this.roomsLoading = false
    } else {
      this.roomList = []
    }
  }
  async fetchRoomList(houseId: string) {
    const rooms = await fetchAll(offset =>
      this.$api.europa.getRooms({ houseIds: [houseId], offset, limit: 100 })
    )
    this.roomList = rooms
    // 选中的房屋只有一个房间时，默认选中这个房间
    this.$nextTick(() => {
      if (this.roomList.length === 1) {
        this.setFormData({ roomId: this.roomList[0].id })
      }
    })
  }
}
